<template>
  <div class="layout layout--onlybranding">
    <client-only>
      <LoadingSpinner />
    </client-only>
    <Branding
      ref="branding"
      class="layout__branding"
    />
    <slot />
    <div id="teleport" />
  </div>
</template>

<script>
import Layout from '@/mixins/layout';
import Branding from '@/components/branding.vue';

export default {
  name: 'OnlyBranding',
  components: {
    Branding,
  },
  mixins: [Layout],
};
</script>

<style lang="scss">
.layout--onlybranding {
  background-color: white;
  height: 100vh;

  .layout__branding {
    position: static;
    height: 50px;
    padding: 0 24px;
  }

  .branding__wrapper {
    height: 100%;
  }

  .branding__logo {
    padding: 12px;
    margin: 0;
    height: 100%;

    .icon--logo {
      width: 135px;
      height: 100%;
    }
  }
  .branding__claim {
    margin: 0;
    display: flex;
    align-items: center;
  }
}
</style>
